/**
 * UseContentElementInteract
 *
 * @author: exode <hello@exode.ru>
 */

import { UpsertInteractionElementContentInput, useContentElementInteractMutation } from '@/codegen/graphql';


export const useContentElementInteract = () => {

    const [
        _interact,
        {
            error: interactError,
            loading: interactLoading,
        },
    ] = useContentElementInteractMutation({
        onError: (error) => console.error(error),
    });

    const interact = (
        contentElementUuid: string,
        data: UpsertInteractionElementContentInput['data'],
    ) => {
        return _interact({
            variables: {
                contentElementUuid,
                interaction: { data },
            },
            update: (cache, { data }) => {
                const {
                    contentElement,
                    data: myInteraction,
                } = data?.contentElementInteractionUpsert || {};

                if (contentElement) {
                    cache.modify({
                        id: `ContentElement:${contentElement.id}`,
                        fields: {
                            myInteraction: () => myInteraction,
                        },
                    });
                }
            },
        });
    };

    return {
        interact,
        interactError,
        interactLoading,
    };
};
