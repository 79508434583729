/**
 * UseTaskBlockManage
 *
 * @author: exode <hello@exode.ru>
 */

import {
    CreateElementContentInput,
    UpdateBlockOrderTaskInput,
    UpdateElementContentInput,
    useTaskBlockManageCreateMutation,
    useTaskBlockManageDeleteMutation,
    useTaskBlockManageRecoverMutation,
    useTaskBlockManageUpdateMutation,
    useTaskBlockManageUpdateOrderMutation,
} from '@/codegen/graphql';

import { useBlockManage, UseBlockManageProps } from '@/hooks/apollo';

import { ContentElementItem } from '@/components/ContentElement';


interface Props {
    taskId: number;
    getExistBlocks: UseBlockManageProps['getExistBlocks'];
}


export const useTaskBlockManage = (props: Props) => {

    const { taskId, getExistBlocks } = props;

    const {
        onCreateCacheUpdate,
        onDeleteCacheUpdate,
        onRecoverBlockWrapper,
        updateBlockOrderInCache,
    } = useBlockManage({
        getExistBlocks,
        cacheEntityId: `TaskEntity:${taskId}`,
    });

    /** Create task block */
    const [ _createBlock, {
        error: createBlockError,
        loading: createBlockLoading,
    } ] = useTaskBlockManageCreateMutation({
        onError: error => console.error(error),
        update: (__, { data }) => onCreateCacheUpdate(data?.taskBlockManageCreate),
    });

    const createBlock = (
        contentElement: CreateElementContentInput,
    ) => {
        return _createBlock({
            variables: {
                taskId,
                contentElement,
            },
        });
    };

    /** Update task block */
    const [ _updateBlock, {
        error: updateBlockError,
        loading: updateBlockLoading,
    } ] = useTaskBlockManageUpdateMutation({
        onError: error => console.error(error),
    });

    const updateBlock = (
        contentElementId: number,
        contentElement: UpdateElementContentInput,
    ) => {
        return _updateBlock({
            variables: {
                taskId,
                contentElement,
                contentElementId,
            },
        });
    };

    /** Delete task block */
    const [ _deleteBlock, {
        error: deleteBlockError,
        loading: deleteBlockLoading,
    } ] = useTaskBlockManageDeleteMutation({
        onError: error => console.error(error),
    });

    const deleteBlock = (
        contentElementId: number,
    ) => {
        return _deleteBlock({
            variables: {
                taskId,
                contentElementId,
            },
            update: () => onDeleteCacheUpdate(contentElementId),
        });
    };

    /** Recover task block */
    const [ _recoverBlock, {
        error: recoverBlockError,
        loading: recoverBlockLoading,
    } ] = useTaskBlockManageRecoverMutation({
        onError: error => console.error(error),
    });

    const recoverBlock = (
        contentElementId: number,
        deletedBlock?: ContentElementItem,
    ) => {
        return onRecoverBlockWrapper(
            ({ recoverToCache }) => _recoverBlock({
                variables: {
                    taskId,
                    contentElementId,
                },
                update: (__, { data }) => {
                    if (data && !deletedBlock) {
                        recoverToCache(data?.taskBlockManageRecover);
                    }
                },
            }),
            deletedBlock,
        );
    };

    /** Update task block order */
    const [ _updateBlockOrder, {
        error: updateBlockOrderError,
        loading: updateBlockOrderLoading,
    } ] = useTaskBlockManageUpdateOrderMutation({
        onError: error => console.error(error),
    });

    const updateBlockOrder = (
        blockOrder: UpdateBlockOrderTaskInput,
    ) => {
        return _updateBlockOrder({
            variables: {
                taskId,
                blockOrder,
            },
        });
    };

    return {
        createBlock,
        createBlockError,
        createBlockLoading,
        updateBlock,
        updateBlockError,
        updateBlockLoading,
        deleteBlock,
        deleteBlockError,
        deleteBlockLoading,
        recoverBlock,
        recoverBlockError,
        recoverBlockLoading,
        updateBlockOrder,
        updateBlockOrderError,
        updateBlockOrderLoading,
        updateBlockOrderInCache,
    };
};