/**
 * UseBlockManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { apolloClient } from '@/api/graphql';

import { ContentElementItem } from '@/components/ContentElement';


export interface UseBlockManageProps {
    cacheEntityId: string;
    getExistBlocks: () => ContentElementItem[] | undefined,
}


export const useBlockManage = (props: UseBlockManageProps) => {

    const { cacheEntityId, getExistBlocks } = props;

    const blocksToRef = (blocks: ContentElementItem[]) => {
        return blocks.map(({ id }) => ({ __ref: `ContentElementEntity:${id}` }));
    };

    const onCreateCacheUpdate = (
        newBlock: ContentElementItem | null | undefined,
    ) => {
        const existBlocks = getExistBlocks();

        if (!newBlock || !existBlocks) {
            return console.warn('[Cache]: cachedBlocks отсутствует в кэше');
        }

        apolloClient.cache.modify({
            id: cacheEntityId,
            fields: {
                blocks: () => blocksToRef([
                    ...existBlocks,
                    newBlock,
                ]),
            },
        });
    };

    const onDeleteCacheUpdate = (
        contentElementId: number,
    ) => {
        const existBlocks = getExistBlocks();

        if (!existBlocks) {
            return console.warn('[Cache]: cachedBlocks отсутствует в кэше');
        }

        apolloClient.cache.modify({
            id: cacheEntityId,
            fields: {
                blocks: () => blocksToRef(existBlocks.filter(({ id }) => id !== contentElementId)),
            },
        });
    };

    const onRecoverBlockWrapper = (
        mutationCb: (
            params: {
                recoverToCache: (ce: ContentElementItem) => void;
            },
        ) => void,
        deletedBlock?: ContentElementItem,
    ) => {
        const recoverToCache = (block: ContentElementItem) => {
            const { cache } = apolloClient;

            const existBlocks = getExistBlocks();

            if (!block || !existBlocks) {
                return console.warn('[Cache]: cachedBlocks отсутствует в кэше');
            }

            const orderedBlocks = _.orderBy([
                ...existBlocks,
                block,
            ], 'order');

            cache.modify({
                id: cacheEntityId,
                fields: {
                    blocks: () => blocksToRef(orderedBlocks),
                },
            });
        };

        if (deletedBlock) {
            recoverToCache(deletedBlock);
        }

        return mutationCb({ recoverToCache });
    };

    const updateBlockOrderInCache = (
        orderBlocks: ContentElementItem[],
    ) => {
        const { cache } = apolloClient;

        const existBlocks = getExistBlocks();

        if (!orderBlocks || !existBlocks) {
            return console.warn(`[Cache]: ${cacheEntityId} отсутствуют в кэше`);
        }

        const orderedBlocks = _.orderBy(existBlocks.map((block) => {
            const orderBlock = _.find(orderBlocks, { id: block.id });

            return orderBlock
                ? { ...block, order: orderBlock.order }
                : block;
        }), 'order');

        cache.modify({
            id: cacheEntityId,
            fields: { blocks: () => blocksToRef(orderedBlocks) },
        });
    };

    return {
        onCreateCacheUpdate,
        onDeleteCacheUpdate,
        onRecoverBlockWrapper,
        updateBlockOrderInCache,
    };
};
