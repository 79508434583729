/**
 * Portal util
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { v4 } from 'uuid';

import React, { ReactElement, ReactNode, useEffect } from 'react';

import { observer, PortalKeyType, PortalStore } from '@/store/core/portal';


interface Props {
    id: PortalKeyType;
    children?: ReactElement;
    placeholder?: ReactNode;
    renderer?: boolean;
    order?: number | 'last';
}


const Portal = observer((props: Props) => {

    const {
        id,
        order,
        children,
        renderer,
        placeholder,
    } = props;

    const uuid = [ order === 'last' ? 'zzz' : order, v4() ].filter(e => !_.isNil(e)).join(':');

    if (renderer) {
        return (
            <>
                {_.map(!_.isEmpty(PortalStore.portals[id])
                        ? PortalStore.portals[id]
                        : placeholder ? [ placeholder ] : [],
                    (p) => p,
                )}
            </>
        );
    }

    useEffect(() => {
        if (children) {
            PortalStore.addToPortal(uuid, id, children);
        }

        return () => {
            PortalStore.removeFromPortal(uuid, id);
        };
    }, [ children ]);

    return <></>;
});


export { Portal };
