/**
 * Moment UZ
 *
 * @author: exode <hello@exode.ru>
 */

import moment, { Moment } from 'moment';


const weekdaysMin = 'Ya_Du_Se_Cho_Pa_Ju_Sha'.split('_');
const weekdaysShort = 'Yak_Dush_Sesh_Chor_Pay_Jum_Shan'.split('_');
const monthsShort = 'yan_fev_mar_apr_may_iyun_iyul_avg_sen_okt_noy_dek'.split('_');

moment.defineLocale('uz', {
    weekdaysMin: weekdaysMin,
    monthsShort: monthsShort,
    week: { dow: 1, doy: 7 },
    weekdaysShort: weekdaysShort,
    dayOfMonthOrdinalParse: /\d{1,2}-chi/,
    ordinal: (number: number) => number + '-chi',
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'D MMMM YYYY, dddd HH:mm',
    },
    calendar: {
        sameDay: '[Bugun soat] LT [da]',
        nextDay: '[Ertaga] LT [da]',
        nextWeek: 'dddd [kuni soat] LT [da]',
        lastDay: '[Kecha soat] LT [da]',
        lastWeek: '[O\'tgan] dddd [kuni soat] LT [da]',
        sameElse: 'L',
    },
    relativeTime: {
        future: '%s ichida',
        past: '%s oldin',
        s: 'fursat',
        ss: '%d fursat',
        m: 'bir daqiqa',
        mm: '%d daqiqa',
        h: 'bir soat',
        hh: '%d soat',
        d: 'bir kun',
        dd: '%d kun',
        M: 'bir oy',
        MM: '%d oy',
        y: 'bir yil',
        yy: '%d yil',
    },
    months: (m: Moment, format?: string) => {
        const months = {
            'nominative': 'yanvar_fevral_mart_aprel_may_iyun_iyul_avgust_sentabr_oktabr_noyabr_dekabr'.split('_'),
            'accusative': 'yanvarni_fevralni_martni_aprelni_mayni_iyunni_iyulni_avgustni_sentabrni_oktabrni_noyabrni_dekabrni'.split('_'),
        };

        return format === 'format'
            ? months.accusative[m.month()]
            : months.nominative[m.month()];
    },
    weekdays: (d: Moment, format?: string) => {
        const weekdays = {
            'nominative': 'Yakshanba_Dushanba_Seshanba_Chorshanba_Payshanba_Juma_Shanba'.split('_'),
            'accusative': 'Yakshanbani_Dushanbani_Seshanbani_Chorshanbani_Payshanbani_Jumani_Shanbani'.split('_'),
        };

        return format === 'format'
            ? weekdays.accusative[d.day()]
            : weekdays.nominative[d.day()];
    },
});
