/**
 * PlaceholderWrapper component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { If } from '@/cutils';

import React, { ReactElement, useEffect, useState } from 'react';

import { Placeholder, PlaceholderProps } from '@/components/Atoms/Placeholder';


interface Props {
    children: ReactElement;
    placeholder: PlaceholderProps;
    subCounters?: (setCounts: (index: string, count: number) => void) => ReactElement[];
    count?: number | null;
}


const PlaceholderWrapper = (props: Props) => {

    const [ count, setCount ] = useState(props.count || 0);
    const [ counts, setCounts ] = useState<Record<number, number>>({});

    useEffect(() => {
        setCount((props.count || 0) + _.sum(Object.values(counts)));
    }, [ props.count, counts ]);

    return (
        <>
            <If is={count > 0}>
                {React.cloneElement(props.children, { setCount })}
            </If>

            <If is={count === 0}>
                <Placeholder {...props.placeholder} />
            </If>

            {(
                props.subCounters?.((i, c) => setCounts((prevState) => ({ ...prevState, [i]: c })))
                    ?.map((e) => React.cloneElement(e, { setCounts }))
            )}
        </>
    );
};


export { PlaceholderWrapper };
