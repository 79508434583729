/**
 * LaunchSearchSelect
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useI18n } from '@/hooks/core';

import { FilterLaunchProductInput, useProductLaunchFindManyLazyQuery } from '@/codegen/graphql';

import { SearchSelect } from '@/components/Atoms/SearchSelect';


interface Props {
    onSelect: (params: any) => void;
    dataTest?: string;
    selectedLaunchIds?: number[];
    filter?: FilterLaunchProductInput;
}


const LaunchSearchSelect = (props: Props) => {

    const {
        onSelect,
        dataTest,
        selectedLaunchIds,
        filter = {},
    } = props;

    const { t } = useI18n('components.Course.LaunchSearchSelect');

    /** Transform filter */
    filter.productIds = filter?.productIds?.filter((e) => e);

    const [ search, { data, loading, variables } ] = useProductLaunchFindManyLazyQuery({
        fetchPolicy: 'network-only',
        onError: error => console.error(error),
    });

    const options = data?.productLaunchFindMany.items?.map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    const searchCallback = async (value: string) => {
        await search({
            variables: {
                list: { take: 100 },
                filter: { ...filter, search: value },
            },
        });
    };

    return (
        <SearchSelect loading={loading}
                      onSelect={onSelect}
                      dataTest={dataTest}
                      options={options || []}
                      selectedIds={selectedLaunchIds}
                      searchCallback={searchCallback}
                      placeholder={t('enterLaunchName')}
                      initialSearch={{
                          skip: _.isEmpty(filter?.productIds),
                          deps: [ JSON.stringify(filter?.productIds) ],
                      }}
                      emptyText={(
                          variables?.filter?.search
                              ? t('searchDidNotGiveResults')
                              : t('startTypingLaunchName')
                      )}/>
    );
};


export { LaunchSearchSelect };
