/**
 * EditUserFormView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ManageSchoolUsersPageStore, observer } from '../../store';

import { IS_MARKETPLACE } from '@/root/src/env';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Tabs, TabsItem } from '@exode.ru/vkui';

import {
    Icon20ArrowRightSquareOutline,
    Icon20AudiobookOutline,
    Icon20EducationOutline,
    Icon20KeyOutline,
    Icon20UserOutline,
} from '@vkontakte/icons';

import { StudyTab } from './tabs/StudyTab';
import { ProfileTab } from './tabs/ProfileTab';
import { SessionsTab } from './tabs/SessionsTab';
import { StudyProgressTab } from './tabs/StudyProgressTab';

import { UserEditTab } from '../../interfaces';


export interface EditUserFormViewProps {
    userId: number;
}


const EditUserFormView = observer((props: EditUserFormViewProps) => {

    const { userId } = props;

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    const {
        userEditActiveTab,
        setUserEditActiveTab,
    } = ManageSchoolUsersPageStore;

    const userTabs = [
        {
            type: UserEditTab.Study,
            before: <Icon20EducationOutline/>,
            title: t('study'),
        },
        {
            type: UserEditTab.Profile,
            before: <Icon20UserOutline/>,
            title: t('profile'),
        },
        {
            type: UserEditTab.Sessions,
            before: <Icon20KeyOutline/>,
            title: t('sessions'),
        },
    ] as const;

    const educationTabs = [
        {
            type: UserEditTab.Study,
            before: <Icon20ArrowRightSquareOutline style={{ transform: 'rotate(180deg)' }}/>,
            title: t('backToAllStudy'),
            hidden: IS_MARKETPLACE,
        },
        {
            type: UserEditTab.StudyProgress,
            before: <Icon20AudiobookOutline/>,
            title: t('studyProgress'),
            hidden: false,
        },
    ] as const;

    const tabComponent = (() => {
        switch (userEditActiveTab) {
            case UserEditTab.Study:
                return <StudyTab userId={userId}/>;

            case UserEditTab.Profile:
                return <ProfileTab userId={+userId}/>;

            case UserEditTab.Sessions:
                return <SessionsTab userId={userId}/>;

            case UserEditTab.StudyProgress:
                return <StudyProgressTab/>;
        }
    })();

    return (
        <div className="p-4 pt-0">
            <If is={[
                UserEditTab.Study,
                UserEditTab.Profile,
                UserEditTab.Sessions,
            ].includes(userEditActiveTab)}>
                <Tabs mode="accent">
                    {userTabs.map(({ type, title, ...rest }, index) => (
                        <TabsItem {...rest} key={index}
                                  data-test={`user.${type}-tab`}
                                  selected={type === userEditActiveTab}
                                  className="gap-1 py-2 flex-1 first:ml-0"
                                  onClick={() => setUserEditActiveTab(type)}>
                            {title}
                        </TabsItem>
                    ))}
                </Tabs>
            </If>

            <If is={[
                UserEditTab.StudyProgress,
            ].includes(userEditActiveTab)}>
                <Tabs mode="accent">
                    {educationTabs.filter((e) => !e.hidden)
                        .map(({ type, title, ...rest }, index) => (
                            <TabsItem {...rest} key={index}
                                      data-test={`user.${type}-tab`}
                                      className="gap-1 py-2 first:ml-0"
                                      selected={type === userEditActiveTab}
                                      onClick={() => setUserEditActiveTab(type)}>
                                {title}
                            </TabsItem>
                        ))}
                </Tabs>
            </If>

            <div className="pt-2 pb-3">
                {tabComponent}
            </div>
        </div>
    );
});


export { EditUserFormView };
