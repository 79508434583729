/**
 * ChatListSeparatorItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Caption } from '@exode.ru/vkui';


interface Props {
    caption: string;
    className?: string;
    isRounded?: boolean;
}


const ChatListSeparatorItem = (props: Props) => {

    const {
        caption,
        isRounded = false,
        className = 'thin-border-bottom',
    } = props;

    return (
        <Caption className={[
            className,
            isRounded ? 'vk-rounded' : '',
            'px-4 py-2 uppercase fs-12 bg-hover font-medium',
        ].join(' ')}>
            {caption}
        </Caption>
    );
};


export { ChatListSeparatorItem };
