/**
 * Use urlFilters
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useEffect, useLayoutEffect, useState } from 'react';

import { PageParams } from 'router.tsx';
import { useLocation } from '@/router/index';

import { IS_NATIVE } from '@/root/src/env';

import { RoutePathType } from '@/router/paths';
import { StoreCommonType } from '@/types/store';
import { Router } from '@/services/Utils/Router';


const parseValueFromUrl = (value: string) => {
    let parsedValue: any;

    try {
        parsedValue = JSON.parse(value);
    } catch (e) {
        parsedValue = value;
    }

    return parsedValue;
};

const urlQueryToFilter = <T, >(
    searchParams: URLSearchParams,
    defaultFilter: T,
) => {
    const newFilterState: Record<string, any> = {};

    for (const [ key, defaultValue ] of _.entries(defaultFilter as Record<string, any>)) {
        const value = searchParams.get(key);

        if (value && !_.isEmpty(value)) {
            _.assign(newFilterState, {
                [key]: parseValueFromUrl(value),
            });
        } else {
            _.assign(newFilterState, {
                [key]: defaultValue,
            });
        }
    }

    return newFilterState;
};


export const useUrlFilters = <T, Dto, Store extends StoreCommonType>(
    defaultFilter: T,
    defaultSetToUrl: (keyof T)[] = [],
    dtoFilterTransformer: (filter: T) => Partial<Dto> = (filter) => filter as Partial<Dto>,
    storeOptions?: {
        store: Store,
        filterField?: keyof NonNullable<typeof storeOptions>['store']['state']['filter']
    },
    routerOptions?: {
        routerMethod?: 'pushPage' | 'replacePage'
    },
) => {

    const { routerMethod = 'pushPage' } = routerOptions || {};

    const { route: { pageId, params: currentParams } } = useLocation();

    const paramsString = !IS_NATIVE
        ? window.location.search.slice(1)
        : window.location.hash.split('?')?.[1];

    const [ searchParams, setSearchParams ] = useState(new URLSearchParams(paramsString));

    const [ urlFilter, setUrlFilterState ] = useState<Record<string, any>>(
        urlQueryToFilter(searchParams, defaultFilter) as Partial<Dto>,
    );

    const syncFilterWithSearchParams = (searchParams: URLSearchParams) => {
        const newFilterState = urlQueryToFilter(searchParams, defaultFilter);

        setUrlFilterState(newFilterState);

        /** If provided store - sync with store filter */
        if (storeOptions) {
            const { store, filterField } = storeOptions;

            _.set(
                store.state,
                [ 'filter', filterField || '' ].filter(e => e).join('.'),
                dtoFilterTransformer({
                    ..._.get(store.state.filter, filterField || ''),
                    ...newFilterState as T,
                }),
            );
        }
    };

    const getPersistFromCurrentParams = (omit: (keyof T)[] = []) => {
        return _.omit({ ...currentParams }, omit) as PageParams;
    };

    const setUrlFilter = (
        filters: Partial<T>,
        options: {
            mode?: 'replace' | 'merge';
            moveToFirstPage?: boolean;
        } = {},
    ) => {
        const {
            mode = 'merge',
            moveToFirstPage = !!currentParams?.page,
        } = options;

        const params = _.transform(filters as Record<string, any>, (result: { [key: string]: any }, value, key) => {
            result[key.trim()] = JSON.stringify(value);
        });

        const omit: (keyof T)[] = mode === 'replace'
            ? _.keys(defaultFilter) as (keyof T)[]
            : [];

        const diffWithDefaultFilter = _.keys(_.pickBy(params, (v, k) => {
            return !_.isEqual(defaultFilter[k as keyof T], parseValueFromUrl(v))
                || currentParams[k] && !_.isEqual(defaultFilter[k as keyof T], parseValueFromUrl(currentParams[k]));
        }));

        Router[routerMethod](pageId, {
            ...getPersistFromCurrentParams(omit as (keyof T)[]),
            ..._.pick(params, diffWithDefaultFilter),
        });

        if (moveToFirstPage && `${currentParams?.page}` !== '1') {
            Router.updateParams({ page: '1' });
        }
    };

    const removeUrlFilter = (remove?: (keyof T)[]) => {
        Router[routerMethod](
            pageId as RoutePathType,
            getPersistFromCurrentParams((remove || _.keys(defaultFilter)) as unknown as (keyof T)[]),
        );
    };

    useEffect(() => {
        for (const setToUrl of defaultSetToUrl) {
            if (!searchParams.get(setToUrl as string)) {
                Router.updateParams({ [setToUrl]: urlFilter[setToUrl as string] });
            }
        }
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(paramsString);

        setSearchParams(searchParams);
        syncFilterWithSearchParams(searchParams);
    }, [ paramsString ]);

    useLayoutEffect(() => {
        syncFilterWithSearchParams(searchParams);
    }, []);

    return {
        setUrlFilter,
        removeUrlFilter,
        urlFilter: urlFilter as T,
        dtoFilter: dtoFilterTransformer(urlFilter as T) as Partial<Dto>,
    };
};
