/**
 * ScrollHelper
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { UiService } from '@/services/Ui/Ui';


class ScrollHelper {

    /**
     * Current scroll position of the window
     * @returns {number}
     */
    static get scrollTop() {
        return window.scrollY;
    }

    /**
     * Toggle scrollable property on the body element
     * @param {boolean} freeze
     */
    static freezeScroll(freeze: boolean) {
        document.body.style.overflow = freeze ? 'hidden' : 'auto';
    }

    /**
     * Scroll window to the specified height
     * @param {number} top
     * @param {boolean} smooth
     * @param element
     */
    static to(
        top: number = 0,
        smooth: boolean = false,
        element: Window | HTMLDivElement | null = window,
    ) {
        const behavior = smooth ? 'smooth' : 'auto';
        const options = { left: 0, behavior } as ScrollToOptions;

        return element?.scrollTo({ top, ...options });
    }

    /**
     * Calculate scroll from bottom
     * @param {React.MutableRefObject<HTMLDivElement | null>} ref
     * @returns {number}
     */
    static scrollFromBottom(ref: React.MutableRefObject<HTMLDivElement | null>) {
        return (ref.current?.scrollHeight || 0)
            - (ref.current?.clientHeight || 0)
            - (ref.current?.scrollTop || 0);
    }

    /**
     * Scroll to bottom
     * @param {React.MutableRefObject<HTMLDivElement | null>} ref
     * @param {boolean} smooth
     */
    static scrollToBottom(
        ref: React.MutableRefObject<HTMLDivElement | null>,
        smooth: boolean = false,
    ) {
        if (!ref.current) {
            return;
        }

        const behavior = smooth ? 'smooth' : 'auto';
        const options = { left: 0, behavior } as ScrollToOptions;

        ref.current.scrollTo({ top: ref.current.scrollHeight, ...options });
    }

    /**
     * Calc top to the element
     * @param {HTMLDivElement} element
     * @param {number} offset
     * @returns {number}
     */
    static calcTopToElement(
        element: HTMLElement | HTMLDivElement | undefined,
        offset?: number,
    ) {
        return (element?.getBoundingClientRect().top || 0)
            - document.body.getBoundingClientRect().top
            + (offset || 0)
            + UiService.getSafeAreaInsetValue.top;
    }

}


export { ScrollHelper };
