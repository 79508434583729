/**
 * Page space types
 *
 * @author: exode <hello@exode.ru>
 */

export enum PageSpace {
    /** Show and hide by platform */
    SchoolPlatform = 'SchoolPlatform',
    MarketplacePlatform = 'MarketplacePlatform',
    BizWelcomePlatform = 'BizWelcomePlatform',

    /** Set active tab by space */
    AdminRightMenu = 'AdminRightMenu',
    FinancesRightMenu = 'FinancesRightMenu',
    PracticeRightMenu = 'PracticeRightMenu',
    ProductsRightMenu = 'ProductsRightMenu',
    PromotionRightMenu = 'PromotionRightMenu',
    UsersRightMenu = 'UsersRightMenu',
    SchoolRightMenu = 'SchoolRightMenu',
    SellerRightMenu = 'SellerRightMenu',
    StudentsRightMenu = 'StudentsRightMenu',
    TaskBaseRightMenu = 'TaskBaseRightMenu',
    HomeworkRightMenu = 'HomeworkRightMenu',
}
