/**
 * ConfirmButton component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { cloneElement, ReactElement, useEffect, useState } from 'react';

import { observer, PreferenceStore } from '@/store/preference/preference';

import { If } from '@/cutils';
import { Time } from '@/utils';
import { useConfirm, useI18n } from '@/hooks/core';

import { Spinner } from '@exode.ru/vkui';
import { IconButton } from '@mui/material';
import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Placement } from '@exode.ru/vkui/dist/components/Popper/Popper';
import { Icon24CancelOutline, Icon24DeleteOutline } from '@vkontakte/icons';

import { ConfirmModalProps } from '@/modals/Utils/Confirmation';


interface Props {
    callback: Function;
    confirmation?: 'click' | 'useConfirm';
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    text?: string;
    disabled?: boolean;
    placement?: Placement;
    replaceDelay?: number;
    children?: ReactElement;
    confirmRegularButton?: ReactElement;
    stopPropagation?: boolean;
    offsetSkidding?: number;
    offsetDistance?: number;
    noWrapper?: boolean;
    loading?: boolean;
    className?: string;
    iconFill?: string;
    dataTest?: string;
    confirmProps?: Record<any, any>;
    initialIcon?: ReactElement;
    confirmIcon?: ReactElement;
    useConfirmProps?: ConfirmModalProps;
}

const ConfirmButton = observer((props: Props) => {

    const { t } = useI18n('components.Atoms.ConfirmButton');

    const {
        onClick,
        callback,
        disabled,
        children,
        confirmRegularButton,
        stopPropagation,
        offsetSkidding,
        offsetDistance,
        noWrapper,
        iconFill,
        initialIcon,
        confirmIcon,
        loading,
        useConfirmProps,
        dataTest,
        confirmation = 'click',
        className = '',
        confirmProps = {},
        text = t('clickToConfirm'),
        placement = 'top',
        replaceDelay = 4000,
    } = props;

    const [ status, setStatus ] = useState<'initial' | 'confirm'>('initial');

    const { openConfirm: openConfirmDelete } = useConfirmProps
        ? useConfirm(useConfirmProps)
        : { openConfirm: () => {} };

    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        onClick?.(e);

        if (confirmation === 'useConfirm') {
            return openConfirmDelete();
        }

        if (status === 'initial') {
            setStatus('confirm');
        }

        if (status === 'confirm') {
            callback();
            setStatus('initial');
        }

        stopPropagation && e.stopPropagation();
    };

    const replaceIcon = async () => {
        await Time.timer(replaceDelay);

        setStatus('initial');
    };

    const targetChildren = children
        ? cloneElement(
            status === 'confirm' && confirmRegularButton || children,
            _.omitBy({
                dataTest,
                className: className || undefined,
                onClick: !disabled ? handleClick : undefined,
                ...(status === 'confirm' ? confirmProps : {}),
            }, _.isUndefined),
        )
        : (
            <IconButton data-test={dataTest}
                        onClick={handleClick}
                        disabled={loading || disabled}
                        className={noWrapper ? className : ''}
                        {...confirmProps}>
                <If is={!loading}>
                    <If is={status === 'initial'}>
                        {initialIcon || (
                            <Icon24CancelOutline width={confirmProps?.width}
                                                 height={confirmProps?.height}
                                                 fill={iconFill || 'var(--text_secondary)'}/>
                        )}
                    </If>

                    <If is={status === 'confirm'}>
                        {confirmIcon || (
                            <Icon24DeleteOutline fill="var(--destructive)"
                                                 width={confirmProps?.width}
                                                 height={confirmProps?.height}/>
                        )}
                    </If>
                </If>

                <If is={!!loading}>
                    <Spinner/>
                </If>
            </IconButton>
        );

    const Tooltip = (component: ReactElement) => (
        <TextTooltip text={text}
                     children={component}
                     placement={placement}
                     offsetSkidding={offsetSkidding}
                     offsetDistance={offsetDistance}
                     appearance={PreferenceStore.isDark ? 'white' : 'black'}/>
    );

    useEffect(() => {
        status === 'confirm' && replaceIcon();
    }, [ status ]);

    return (
        <>
            <If is={status === 'confirm'}>
                <If is={!!noWrapper}>
                    {Tooltip(targetChildren)}
                </If>

                <If is={!noWrapper}>
                    {Tooltip(
                        <div className={className}>
                            {targetChildren}
                        </div>,
                    )}
                </If>
            </If>

            <If is={status === 'initial'}>
                <If is={!!noWrapper}>
                    {targetChildren}
                </If>

                <If is={!noWrapper}>
                    <div className={className}>
                        {targetChildren}
                    </div>
                </If>
            </If>
        </>
    );
});


export { ConfirmButton };
