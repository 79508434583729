/**
 * FooterPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { IS_SCHOOL } from '@/root/src/env';

import { UserAuthStore } from '@/store/user/auth';
import { observer, PlatformStore, SchoolStore } from '@/store/platform';

import { useI18n } from '@/hooks/core';
import { Permission } from '@/codegen/graphql';
import { If, Link, Platform, Rbac } from '@/cutils';

import { Button, Footer } from '@exode.ru/vkui';
import { Icon16LinkOutline, Icon24LifebuoyOutline } from '@vkontakte/icons';

import { LegalDocLink } from '@/components/Company/Legal';
import { SubscriptionInfo } from '@/components/School/SubscriptionUpgrade';


const FooterPart = observer(() => {

    const { COMMON } = PlatformStore;

    const { t } = useI18n('components.Desktop.Navigation.Menu');

    const supportButton = (
        <Button size="m"
                mode="secondary"
                before={<Icon24LifebuoyOutline/>}
                className="w-[197px] dark:border-transparent border-solid border-[0.12em]">
            <span>{t('needHelp')}</span>
        </Button>
    );

    return (
        <Footer className={[
            'fixed bottom-5 flex justify-items-start flex-wrap',
            'gap-4 my-0 mx-8 fs-12 w-[230px] text-left max-w-full break-words',
        ].join(' ')}>
            <If is={IS_SCHOOL && UserAuthStore.isManager}>
                <SubscriptionInfo access={SchoolStore.school?.access}/>
            </If>

            <Platform.Market>
                <div className="w-full flex">
                    <Link pushPage={{ id: '/support' }}>
                        {supportButton}
                    </Link>
                </div>
            </Platform.Market>

            <Platform.School>
                <If is={!!COMMON?.organization.info.messengerSupportUrl}>
                    <div className="w-full flex">
                        <Link blank toOuter={COMMON?.organization.info.messengerSupportUrl}>
                            {supportButton}
                        </Link>
                    </div>
                </If>

                <Rbac.Permission permissions={[ Permission.SchoolCustomizeSite ]}>
                    <If is={!COMMON?.organization.info.messengerSupportUrl}>
                        <Link pushPage={{
                            id: '/manage/school/settings/blocks',
                            params: { scrollToContacts: 'true' },
                        }}>
                            <div className="flex items-center gap-1">
                                <Icon16LinkOutline fill="var(--accent)"/>
                                <span className="text-accent">{t('addSupportContact')}</span>
                            </div>
                        </Link>
                    </If>
                </Rbac.Permission>
            </Platform.School>

            <div className="flex gap-1.5 flex-wrap w-full">
                <Platform.Market>
                    <Link pushPage={{ id: '/about' }}>
                        <span>{t('companyAbout')}</span>
                    </Link>
                </Platform.Market>

                <LegalDocLink hideIfEmpty type="PartnerAgreement" name={t('termsOfUse')}/>

                <LegalDocLink hideIfEmpty type="DataPolicy" name={t('politics')}/>
            </div>

            <div>
                <Link disabled={IS_SCHOOL} pushPage={{ id: '/about' }}>
                    <span>
                        {[ '©', COMMON?.organization.info.name ].join(' ')}
                    </span>
                </Link>
            </div>
        </Footer>
    );
});


export { FooterPart };
