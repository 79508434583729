/**
 * CustomScrollbar styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { isWindows } from 'react-device-detect';

import { customScrollbar } from '@/styles/modules/scrollbar';


export const CustomScrollbar = styled.div`
    ${customScrollbar()}
`;

export const WindowsCustomScrollbar = styled.div`
    ${isWindows && customScrollbar()}
`;
