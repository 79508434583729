/**
 * FooterContactItemPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If, Link } from '@/cutils';
import { RoutePathType } from '@/router/paths';

import { Router } from '@/services/Utils/Router';

import { Icon16Message } from '@vkontakte/icons';

import { FooterBlockItemBody } from './FooterBlockItemPart';


interface Props {
    item: FooterBlockItemBody;
}


const FooterContactItemPart = (props: Props) => {

    const { item } = props;

    const { contact, link } = item;

    const { name, role, phone, email } = contact || {};

    const match = Router.matchLinkPathAppRoute(link || '');

    return (
        <div className="flex flex-col gap-2">
            <span className="fs-14 m:fs-15 m-0">
                {role}
            </span>

            <Link blank={!match}
                  toOuter={!match ? link : undefined}
                  pushPage={match && { id: match.path as RoutePathType, params: match.params }}>
                <span className="flex gap-1 items-center fs-14 m:fs-15 m-0 text-accent">
                    {name}
                    {link ? <Icon16Message width={14} height={14}/> : null}
                </span>
            </Link>

            <If is={!!email}>
                <a href={`mailto:${email}`} className="fs-14 m:fs-15 text-accent no-underline m-0 !cursor-pointer">
                    {email}
                </a>
            </If>

            <If is={!!phone}>
                <>
                    {phone?.split(',').map((e) => e?.trim()).map((item, index) => (
                        <a key={index}
                           href={`tel:${item}`}
                           className="fs-14 m:fs-15 text-accent no-underline m-0 !cursor-pointer">
                            {item}
                        </a>
                    ))}
                </>
            </If>
        </div>
    );
};


export { FooterContactItemPart };
