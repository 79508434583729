/**
 * UseTaskManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useApolloClient } from '@apollo/client';

import { Notify } from '@/cutils';
import { ManageTasksListPageStore } from '@/pages/Manage/Tasks/List/store';

import { GqlResult } from '@/types/graphql';

import { useBatchUpdateCacheFields, useI18n, usePrompt } from '@/hooks/core';

import {
    EducationSubject,
    SubjectCategoryFindManyDocument,
    SubjectCategoryManageCreateMutationResult,
    TaskAnswerType,
    TaskCommonFragmentFragment,
    TaskComplexity,
    TaskEntity,
    TaskFindManyDocument,
    TaskFindManyQuery,
    TaskManageCreateMutationOptions,
    TaskManageCreateMutationResult,
    UpdateTaskInput,
    useSubjectCategoryManageCreateMutation,
    useTaskManageCreateMutation,
    useTaskManageUpdateMutation,
} from '@/codegen/graphql';


import { Icon16Cancel } from '@vkontakte/icons';

import { useTaskBaseFilter } from '@/pages/Manage/Tasks/List/filter';


export const useTaskManage = () => {

    const { t } = useI18n('hooks.apollo.task');

    const apolloClient = useApolloClient();
    const { dtoFilter } = useTaskBaseFilter();

    const [ taskManageCreate, { loading: createTaskLoading } ] = useTaskManageCreateMutation();
    const [ taskManageUpdate, { loading: updateTaskLoading } ] = useTaskManageUpdateMutation();

    const [
        _createSubjectCategory,
        { loading: createSubjectCategoryLoading },
    ] = useSubjectCategoryManageCreateMutation();

    const createTask = (options: TaskManageCreateMutationOptions) => taskManageCreate({
        onError(error) {
            console.error(error);

            Notify.vkui({
                message: error.message,
                appearance: 'error',
                icon: <Icon16Cancel/>,
            });
        },
        update: (cache, { data }) => {
            const variables = {
                list: { ...ManageTasksListPageStore.state.list },
                filter: {
                    ...dtoFilter,
                    administrate: false,
                    manage: true,
                },
            };

            const cachedTasks = cache.readQuery<TaskFindManyQuery>({
                query: TaskFindManyDocument,
                variables,
            });

            if (!cachedTasks) {
                return console.warn('[Cache]: cachedTasks отсутствуют в кэше');
            }

            data?.taskManageCreate && cache.writeQuery<TaskFindManyQuery>({
                query: TaskFindManyDocument,
                variables,
                data: {
                    taskFindMany: {
                        __typename: 'ListTaskOutput',
                        pages: cachedTasks.taskFindMany.pages,
                        items: [ data?.taskManageCreate, ...(cachedTasks.taskFindMany.items ?? []) ],
                    },
                },
            });
        },
        ...options,
    });

    const updateTask = async (
        taskId: number,
        task: UpdateTaskInput,
        onCompleted?: (task: GqlResult<TaskManageCreateMutationResult>['taskManageCreate']) => void,
    ) => {
        return taskManageUpdate({
            variables: {
                taskId: taskId,
                task: {
                    ..._.omit(task, [ 'uuid' ]),
                },
            },
            onCompleted: (data) => {
                onCompleted?.(data?.taskManageUpdate);
            },
            update: (cache, { data }) => {
                const fields = data?.taskManageUpdate;

                fields && data?.taskManageUpdate && cache.modify({
                    id: `TaskEntity:${fields.id}`,
                    fields: useBatchUpdateCacheFields<TaskEntity>(fields),
                });
            },
            onError(error) {
                console.error(error);

                Notify.vkui({
                    message: error.message,
                    appearance: 'error',
                    icon: <Icon16Cancel/>,
                });
            },
        });
    };

    const createSubjectCategory = (
        subject: EducationSubject,
        onCreate?: (
            subjectCategory: GqlResult<SubjectCategoryManageCreateMutationResult>['subjectCategoryManageCreate'],
        ) => void,
    ) => {
        const { openPrompt: openConfirmCreateCategory } = usePrompt({
            title: t('creatingTopic'),
            subtitle: t('youCanCreateTopicsToBeExplored'),
            isLoading: createSubjectCategoryLoading,
            buttons: {
                confirm: { text: t('create') },
            },
            prompt: {
                required: true,
                mode: 'input',
                minLength: 2,
                maxLength: 20,
                placeholder: t('topicTitle'),
            },
            onConfirm: ({ prompt }) => _createSubjectCategory({
                variables: {
                    category: {
                        subject,
                        name: `${prompt}`,
                    },
                },
                onCompleted: ({ subjectCategoryManageCreate }) => {
                    onCreate?.(subjectCategoryManageCreate);

                    apolloClient.refetchQueries({
                        include: [
                            SubjectCategoryFindManyDocument,
                        ],
                    });
                },
            }),
        });

        return { openConfirmCreateCategory };
    };

    const getInitialValues = (task: TaskCommonFragmentFragment) => {
        return {
            title: task.title || '',
            maxPoint: task.maxPoint || 1,
            archived: task.archived || false,
            contentTypes: task.contentTypes || [],
            subject: task.subject || EducationSubject.EgeRussian,
            answerType: task.answerType || TaskAnswerType.Single,
            complexity: task.complexity || TaskComplexity.Medium,
            subjectCategoryId: task.subjectCategory?.id || null as unknown as number,
        } as const;
    };

    return {
        createTask,
        createTaskLoading,
        updateTask,
        updateTaskLoading,
        createSubjectCategory,
        createSubjectCategoryLoading,
        getInitialValues,
    };
};
