/**
 * UserActionsDropdown
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useSchoolUser } from '@/hooks/apollo';

import { GqlResult } from '@/types/graphql';
import { SchoolUserFindOneQueryResult } from '@/codegen/graphql';

import { Button, SimpleCell } from '@exode.ru/vkui';
import { Dropdown } from '@exode.ru/vkui/dist/unstable';

import {
    Icon20Cancel,
    Icon20MenuOutline,
    Icon28AddCircleOutline,
    Icon28CancelShieldOutline,
    Icon28CheckShieldOutline,
} from '@vkontakte/icons';

import { ConfirmButton } from '@/components/Atoms/ConfirmButton';


interface Props {
    user: GqlResult<SchoolUserFindOneQueryResult>['schoolUserFindOne'];
}


const UserActionsDropdown = (props: Props) => {

    const { user } = props;

    const [ showDropdown, setShowDropdown ] = useState(false);

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    const {
        schoolUserManageUpdate,
        schoolUserManageUpdateLoading,
    } = useSchoolUser();

    const isLoading = [
        schoolUserManageUpdateLoading,
    ].some((e) => e);

    return (
        <Dropdown action="click"
                  shown={showDropdown}
                  className="overflow-hidden"
                  onShownChange={setShowDropdown}
                  content={(
                      <>
                          <SimpleCell className="!rounded-none next-release"
                                      subtitle={t('addAccessManually')}
                                      before={<Icon28AddCircleOutline/>}>
                              {t('addToCourse')}
                          </SimpleCell>

                          <If is={user.banned}>
                              <SimpleCell className="!rounded-none"
                                          subtitle={t('recoverUser')}
                                          before={<Icon28CheckShieldOutline fill="var(--dynamic_green)"/>}
                                          onClick={() => {
                                              setShowDropdown(false);
                                              schoolUserManageUpdate(user.id, { banned: false });
                                          }}>
                                  {t('unBlock')}
                              </SimpleCell>
                          </If>

                          <If is={!user.banned}>
                              <ConfirmButton dataTest="user.block-comfirm"
                                             confirmProps={{ children: t('confirmBlockUser') }}
                                             callback={() => {
                                                 setShowDropdown(false);
                                                 schoolUserManageUpdate(user.id, { banned: true });
                                             }}>
                                  <SimpleCell data-test="user.block"
                                              subtitle={t('exitAllSessionsAndBlock')}
                                              className="!rounded-none text-destructive"
                                              before={<Icon28CancelShieldOutline fill="var(--destructive)"/>}>
                                      {t('block')}
                                  </SimpleCell>
                              </ConfirmButton>
                          </If>
                      </>
                  )}>
            <Button stretched
                    size="m"
                    mode="secondary"
                    loading={isLoading}
                    data-test="user.actions"
                    before={showDropdown ? <Icon20Cancel/> : <Icon20MenuOutline/>}>
                {t('actions')}
            </Button>
        </Dropdown>
    );
};


export { UserActionsDropdown };
