/**
 * Portal
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { ReactElement } from 'react';

import { observer } from 'mobx-react';
import { makeAutoObservable } from 'mobx';


const portalKeys = [
    'panel-header-page:left',
    'panel-header-page:d:left',
    'panel-header-page:content',
    'panel-header-page:content:status',
    'panel-header-page:content:before',
    'panel-header-page:right',
    'panel-header-page:d:right',
    'panel-header-page:context',
    'panel-header-page:d:context',
    'menu:context',
    'menu:context-inner',
    'page:pagination',
    'page:chat:list-panel:search',
] as const;

export type PortalKeyType = typeof portalKeys[number];

type PortalsType = {
    [key in PortalKeyType]?: { [key: string]: ReactElement };
};


class Portal {

    constructor() {
        makeAutoObservable(this);
    }

    /** Порталы */
    portals: PortalsType = {};

    /** Rnd видео */
    rndVideoLink: string | null = null;

    /**
     * Добавление элемента в портал
     * @param {string} uuid
     * @param {PortalKeyType} key
     * @param {React.ReactElement} element
     */
    addToPortal(
        uuid: string,
        key: PortalKeyType,
        element: ReactElement,
    ) {
        const exist = this.portals[key] || {};

        const newPortals = { ...exist, [uuid]: element };

        const sortedPortals: Record<any, any> = {};

        const newPortalKeys = _.keys(newPortals);

        const keys = newPortalKeys.some(k => k.includes(':'))
            ? newPortalKeys.sort()
            : newPortalKeys;

        keys.forEach((key) => sortedPortals[key] = newPortals[key]);

        this.portals[key] = sortedPortals;
    }

    /**
     * Удаление элемента из портала
     * @param {string} uuid
     * @param {PortalKeyType} key
     */
    removeFromPortal(uuid: string, key: PortalKeyType) {
        delete this.portals[key]?.[uuid];
    }

    /**
     * Управление rnd видео
     * @param {Plyr.Source[] | null} rndVideoSource
     */
    setRndVideoSource(rndVideoSource: string | null) {
        if (!_.isEqual(this.rndVideoLink, rndVideoSource)) {
            this.rndVideoLink = rndVideoSource;
        }
    }

}

const PortalStore = new Portal();


export { observer, PortalStore };
