/**
 * Use select action
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect, useState } from 'react';


export const useSelectAction = () => {

    const [ showTooltip, setShowTooltip ] = useState(false);
    const [ showDropdown, setShowDropdown ] = useState(false);

    useEffect(() => {
        if (showTooltip !== showDropdown) {
            setShowTooltip(showDropdown);
        }
    }, [ showDropdown ]);

    return {
        showTooltip,
        setShowTooltip,
        showDropdown,
        setShowDropdown,
    };
};
